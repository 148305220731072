import { render, staticRenderFns } from "./paymentStatusWithCoverImg.vue?vue&type=template&id=7f67db3d"
import script from "./paymentStatusWithCoverImg.vue?vue&type=script&lang=js"
export * from "./paymentStatusWithCoverImg.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1737643512/src/codestar-connections.ap-northeast-1.amazonaws.com/git-http/364953906322/ap-northeast-1/84d36ee7-16b8-4e80-bff0-7e6a249efe8b/OwlTing/owlnest_booking_engine/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7f67db3d')) {
      api.createRecord('7f67db3d', component.options)
    } else {
      api.reload('7f67db3d', component.options)
    }
    module.hot.accept("./paymentStatusWithCoverImg.vue?vue&type=template&id=7f67db3d", function () {
      api.rerender('7f67db3d', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Complete/components/paymentStatusWithCoverImg.vue"
export default component.exports